import * as Routes from 'Routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(query) {
    const url = Routes.api_v2_current_user_memberships_path(query);
    return FetchHelpers.get(url);
  },

  update(membershipId, attrs) {
    const url = Routes.api_v2_current_user_membership_path(membershipId);
    return FetchHelpers.put(url, attrs);
  },

  create(attrs) {
    const url = Routes.api_v2_current_user_memberships_path();
    return FetchHelpers.post(url, attrs);
  },

  destroy(membershipId) {
    const url = Routes.api_v2_current_user_membership_path(membershipId);
    return FetchHelpers.delete(url);
  },
};
